<template>
    <section class="batch-notes">
        <button type="button" class="create-note-button" @click="createNote()">New Note <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
</svg></button>
        <article v-for="note in notes" :key="note.uuid" :class="{saving: note._saving, saved: note._saved, deleting: note._deleting}">
            <nav>
                <p v-if="note._saving">Saving</p>
                <p v-else-if="note._saved">Saved</p>
                <p v-else-if="note._deleting">Deleting</p>
                <p v-else-if="note.last_updated_at">{{new Date(note.last_updated_at).toLocaleString()}}</p>
                <p v-else>New Note</p>
                <button type="button" class="delete-button" @click="note._delete = true">
                    <svg style="width:2em;height:2em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                </button>
            </nav>
            <fieldset>
                <div class="grow-wrap" :data-replicated-value="note.content">
                    <textarea placeholder="Click here to enter your note" v-model="note.content" @change="saveNote(note)"></textarea>
                </div>
            </fieldset>
            <div class="delete-confirm" v-if="note._delete">
                <p>Are you sure you want to delete this note?</p>
                <nav>
                    <button type="button" @click="deleteNote(note)">Yes</button>
                    <button type="button" @click="delete note._delete">Cancel</button>
                </nav>
            </div>
            <div class="deleted" v-if="note._deleted">
                <p>Deleted</p>
                <button type="button" @click="restoreNote(note)">Restore Note</button>
            </div>
        </article>
    </section>

</template>

<script>
export default {
    props: {
        batch_uuid: {
            required: true
        }
    },
    data () {
        return {
            notes: []
        }
    },
    methods: {
        createNote () {
            this.notes.unshift({
                batch_uuid: this.batch_uuid,
                uuid: null,
                content: '',
                created_at: Date.now(),
            })
            this.editing_index = 0
        },
        async saveNote (note) {
            note._saving = true
            // Update note to load the uuid for newly created notes
            const d = await this.$db.saveBatchNote(note)
            note.uuid = d.uuid
            note.last_updated_at = d.last_updated_at
            delete note._saving
            note._saved = true
            setTimeout(() => {
                delete note._saved
            }, 2000)
        },
        async deleteNote (note) {
            delete note._delete
            note._deleting = true
            // Only delete notes that were actually saved
            if (note.uuid) {
                await this.$db.deleteBatchNote(note.uuid)
            }
            delete note._deleting
            note._deleted = true
        },
        async restoreNote (note) {
            delete note._deleted
            this.saveNote(note)
        }
    },
    async mounted () {
        const data = await this.$db.fetchBatchNotes(this.batch_uuid, 1, 100)
        this.notes = data.data
    }
}
</script>

<style scoped>

.batch-notes {
    display:flex;
    flex-direction: column;
    gap:1rem;
    margin-top:1rem;
}

article {
    position:relative;
}
article::after {
    /* border-radius:0.5rem; */
}

article>nav>p {
    font-style: italic;
    font-size:0.8em;
}

.create-note-button {
    align-self: flex-start;
}

.delete-button {
    padding:0 0.5em;
    background-color:lightcoral;
}

/* article.saving::after {
    content: 'SAVING';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    font-size:2em;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: linear-gradient(266deg, #40a543cc, #9ddd00cc);
    background-size: 400% 400%;

    animation: WorkingIndicator 1s infinite;
}

article.saved::after {
    content: 'SAVED';
    background-color:rgba(135, 207, 235, 0.246);
    border:solid 2px dodgerblue;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    font-size:2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

article.deleting::after {
    content: 'DELETING';
    background-color:lightcoral;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.delete-confirm {
    padding:1rem;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:lightcoral;
}

.deleted {
    padding:1rem;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:lightcoral;
}
</style>