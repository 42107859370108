<template>
  <header>
    <nav>
      <router-link to="/menu">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
        <span>Menu</span>
      </router-link>
      <router-link to="/">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M2,22V20C2,20 7,18 12,18C17,18 22,20 22,20V22H2M11.3,9.1C10.1,5.2 4,6.1 4,6.1C4,6.1 4.2,13.9 9.9,12.7C9.5,9.8 8,9 8,9C10.8,9 11,12.4 11,12.4V17C11.3,17 11.7,17 12,17C12.3,17 12.7,17 13,17V12.8C13,12.8 13,8.9 16,7.9C16,7.9 14,10.9 14,12.9C21,13.6 21,4 21,4C21,4 12.1,3 11.3,9.1Z"
          />
        </svg>
        <span>Batches</span>
      </router-link>
      <router-link to="/barcode/scan">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"
          />
        </svg>
        <span>Scan</span>
      </router-link>
      <router-link to="/sync_log">
        <svg
          v-if="sync_status === null"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z"
          />
        </svg>
        <svg
          v-else
          class="sync-in-progress"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19 12V13.5C21.21 13.5 23 15.29 23 17.5C23 18.32 22.75 19.08 22.33 19.71L21.24 18.62C21.41 18.28 21.5 17.9 21.5 17.5C21.5 16.12 20.38 15 19 15V16.5L16.75 14.25L16.72 14.22C16.78 14.17 16.85 14.13 19 12M19 23V21.5C16.79 21.5 15 19.71 15 17.5C15 16.68 15.25 15.92 15.67 15.29L16.76 16.38C16.59 16.72 16.5 17.1 16.5 17.5C16.5 18.88 17.62 20 19 20V18.5L21.25 20.75L21.28 20.78C21.22 20.83 21.15 20.87 19 23M13 17.5C13 13.91 15.91 11 19.5 11C20.78 11 21.97 11.38 23 12C22.13 10.9 20.84 10.14 19.35 10.03C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.03C2.34 8.36 0 10.9 0 14C0 17.31 2.69 20 6 20H13.5C13.18 19.23 13 18.39 13 17.5Z"
          />
        </svg>
        <span>Sync Log</span>
      </router-link>
      <!-- <div v-if="$auth.loading.value">...</div> -->
      <!-- <a v-else-if="!$auth.isAuthenticated.value" @click="login">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,3.18L19,6.3V11.22C19,12.92 18.5,14.65 17.65,16.17C16,14.94 13.26,14.5 12,14.5C10.74,14.5 8,14.94 6.35,16.17C5.5,14.65 5,12.92 5,11.22V6.3L12,3.18M12,6A3.5,3.5 0 0,0 8.5,9.5A3.5,3.5 0 0,0 12,13A3.5,3.5 0 0,0 15.5,9.5A3.5,3.5 0 0,0 12,6M12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8M12,16.5C13.57,16.5 15.64,17.11 16.53,17.84C15.29,19.38 13.7,20.55 12,21C10.3,20.55 8.71,19.38 7.47,17.84C8.37,17.11 10.43,16.5 12,16.5Z" />
        </svg>
        <span>Login</span>
        <i v-if="sync_log_length">{{sync_log_length}}</i>
      </a>
      <a v-else-if="$auth.isAuthenticated.value" @click="logout">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M17.13,17C15.92,18.85 14.11,20.24 12,20.92C9.89,20.24 8.08,18.85 6.87,17C6.53,16.5 6.24,16 6,15.47C6,13.82 8.71,12.47 12,12.47C15.29,12.47 18,13.79 18,15.47C17.76,16 17.47,16.5 17.13,17Z" />
        </svg>
        <span>Logout</span>
      </a>-->
    </nav>
  </header>
  <main>
    <router-view />
  </main>
  <footer>
    <p>
      Version
      <strong>{{ commit_hash }}</strong>
      <i>{{ new Date(commit_date * 1000).toLocaleString() }}</i>
      [DB v{{ db_version }}]
    </p>
  </footer>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      commit_hash: window.commit_hash,
      commit_date: window.commit_date,
      db_version: null,
      sync_log_length: 0,

      sync_log: [],
      current_page: parseInt(this.page),
      total_results: 0,
      last_page: 1,
      sync_status: null,
    };
  },
  async created() {
    this.db_version = (await this.$db._db).version;
  },
  methods: {
    // login() {
    //   this.$auth.loginWithRedirect();
    // },
    // // Log the user out
    // logout() {
    //   this.$auth.logout({
    //     returnTo: window.location.origin
    //   });
    // },
    async getSyncLogLength() {
      this.sync_log_length = await this.$db.getSyncLogLength();
    },
    async doSync() {
      console.log('Doing Sync')
      this.sync_status = 'sycning'
      await this.doPushSync();
      await this.doFetchSync();
      this.sync_timeout = setTimeout(this.doSync.bind(this), 1000 * 30);
      this.sync_status = null
    },
    async doFetchSync() {
      try {
        // const response = await fetch('http://127.0.0.1:8787/database/batches')
        const response = await fetch(
          "https://weta-woods-nursery-backend.mesorchoo.workers.dev/database/batches",
          {
            headers: { 'Authorization': localStorage.getItem('security_key') }
          }
        );
        this.$db.syncDB("batches", await response.json());
        const responsea = await fetch(
          "https://weta-woods-nursery-backend.mesorchoo.workers.dev/database/batch_notes",
          {
            headers: { 'Authorization': localStorage.getItem('security_key') }
          }
        );
        this.$db.syncDB("batch_notes", await responsea.json());
        const responseb = await fetch(
          "https://weta-woods-nursery-backend.mesorchoo.workers.dev/database/batch_costs",
          {
            headers: { 'Authorization': localStorage.getItem('security_key') }
          }
        );
        this.$db.syncDB("batch_costs", await responseb.json());
      } catch (error) {
        console.error(error)
      }
    },
    async doPushSync() {
      try {
        let sync_log = (await this.$db.fetchLog()).data;
        console.log('Push log', sync_log)
        while(sync_log.length) {
          const response = await fetch(
            "https://weta-woods-nursery-backend.mesorchoo.workers.dev/database",
            {
              // const response = await fetch('http://127.0.0.1:8787/database', {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': localStorage.getItem('security_key') },
              body: JSON.stringify(sync_log[0]),
            }
          );
          if (response.status == 200) {
            console.log("Success", await response.json());
            await this.$db.deleteLog(sync_log[0].log_uuid);
            console.log("Log entry deleted");
            sync_log = (await this.$db.fetchLog()).data
          } else {
            console.error(await response.json());
          }
        }
        console.log('Nothing left to sync to cloud')
      } catch(error){
        console.error(error)
        // squash it and ignore
      }
    }
  },
  mounted() {
    this.doSync();
  },
};
</script>

<style>
@import "./assets/style.css";

@media only screen {
  .printing-notice {
    display: block;
  }
  .printing-content {
    display: none;
  }
}

@media only print {
  /* * {
    all:initial;
  } */

  @page {
    size: 90mm 29mm portrait;
  }

  :root {
    font-family: verdana;
    --width: 28mm;
    --height: 89mm;
    width: var(--width);
    height: var(--height);
    overflow: hidden;
  }
  html,
  body {
    margin: 0;
    /* box-shadow: inset 0 0 5px rebeccapurple; */
    /* overflow: hidden; */
    background: none;
    /* width: var(--width);
  height: var(--height); */
  }
  @page {
    /* width: var(--width); */
    /* height: var(--height); */
    /* size: auto landscape; */
    margin-top: 0cm;
    margin-bottom: 0cm;
    margin-right: 0cm;
    margin-left: 0cm;
  }

  .printing-notice {
    display: none;
  }
  .printing-content {
    display: block;
  }

  #app {
    padding: 0;
    background: none;
  }

  #app > header {
    display: none;
  }
  #app > footer {
    display: none;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.sync-in-progress {
  animation: pulse 1s alternate infinite;
}
</style>