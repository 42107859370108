<template>
    <div class="batches-create">
        <h1>New Batch</h1>
        <form action="" @submit="submit">
            <fieldset>
                <label for="name">Name</label>
                <input id="name" required type="text" name="name" v-model="batch.name">
            </fieldset>
            <fieldset>
                <label for="botanical_name">Botanical Name</label>
                <input id="botanical_name" type="text" name="botanical_name" v-model="batch.botanical_name">
            </fieldset>
            <fieldset>
                <label for="source">Source</label>
                <input id="source" type="text" name="source" v-model="batch.source">
            </fieldset>
            <fieldset>
                <label for="source_date">Source Date</label>
                <input id="source_date" type="date" name="source_date" v-model="batch.source_date">
            </fieldset>
            <fieldset>
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number" name="quantity" v-model="batch.quantity">
            </fieldset>
            <fieldset>
                <label for="location">Location</label>
                <input id="location" type="text" name="location" v-model="batch.location">
            </fieldset>
            <button>Save Batch</button>
        </form>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            batch: {

            }
        }
    },
    methods: {
        async submit(event) {
            console.log(this)
            event.preventDefault()
            console.log('Saving', event)

            let b = await this.$db.saveBatch(this.batch)

            this.$router.push(`/batches/${b.uuid}`)
        }
    }
}
</script>