  import {
    createRouter,
    createWebHistory
  } from 'vue-router'
  import Home from '@/views/Home'
  import About from '@/views/About'
  import Batches from '@/views/Batches'
  import Batches_Create from '@/views/Batches_Create'
  import Batches_Show from '@/views/Batches_Show'
  import Batches_Label from '@/views/Batches_Label'
  import Barcode_Scan from '@/views/Barcode_Scan'
  import SyncLog from '@/views/SyncLog'

  import Callback from '@/views/Callback'
  import ErrorPage from '@/views/Error'

// import {
//   routeGuard
// } from '@/auth'


  const routes = [{
      path: '/menu',
      name: 'Menu',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/sync_log',
      name: 'SyncLog',
      component: SyncLog,
    },
    {
      path: '/barcode/scan',
      name: 'Barcode_Scan',
      component: Barcode_Scan,
    },
    {
      path: '/',
      name: 'Batches',
      component: Batches,
    },
    {
      path: '/batches/create',
      name: 'Batches_Create',
      component: Batches_Create,
    },
    {
      path: '/batches/:uuid',
      name: 'Batches_Show',
      component: Batches_Show,
      props: true
    },
    {
      path: '/batches/:uuid/label',
      name: 'Batches_Label',
      component: Batches_Label,
      props: true
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage,
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  export default router