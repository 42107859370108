<template>
    <div class="batches">
        <h1>Batches</h1>
        <fieldset>
            <label for="batch_search">Search</label>
            <input id="batch_search" type="search" v-model="search_string">
        </fieldset>
        <router-link to="/batches/create" class="btn">New Batch</router-link>
        <p>Showing Page {{current_page}} of {{last_page}}</p>
        <router-link class="article" :to="`/batches/${batch.uuid}`" v-for="batch in batches" :key="batch.uuid">
            <h2 class="name">{{batch.name}}</h2>
            <span class="botanical_name">{{batch.botanical_name}}</span>
            <span class="last_updated_at">{{new Date(batch.last_updated_at).toLocaleDateString()}}</span>
            <span class="quantity">{{batch.quantity}}</span>
            <span class="location">{{batch.location}}</span>
        </router-link>

        <nav class="pagination">
            <button :disabled="current_page === 1" @click="current_page = Math.max(1, current_page - 1)">Previous Page</button>
            <button :disabled="current_page === last_page" @click="current_page = Math.min(last_page, current_page + 1)">Next Page</button>
        </nav>
    </div>
</template>

<script>

export default {
    props: {
        page: {
            default: 1
        }
    },
    data: function () {
        return {
            batches: [],
            current_page: parseInt(this.page),
            total_results: 0,
            last_page: 1,
            search_string: '',
        }
    },
    watch:{
        current_page () {
            this.getBatches()
        },
        search_string () {
            this.getBatches()
        }
    },
    methods: {
        async getBatches () {
            const data = await this.$db.fetchBatches(this.current_page, 10, this.search_string)
            this.batches = data.data
            this.total_results = data.total_results
            this.last_page = data.last_page
        }
    },
    mounted () {
        this.getBatches()
    }
}
</script>

<style scoped>
.article {
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-areas: 'name name name' 'botanical_name botanical_name last_updated_at' 'quantity location location';
    border:solid 1px var(--border-color);
    margin:0.5rem 0;
    padding:0.5em;
    background-color:#fff;
    border-radius:0.25em;
    text-decoration:none;
    color:#000;
}

.name {
    font-size:1rem;
    font-weight:bold;
    margin:0;
    grid-area: name;
    text-align:left;
}
.botanical_name {
    font-style: italic;
    grid-area:botanical_name;
    text-align:left;
}
.quantity {
    font-size:1.4rem;
    grid-area: quantity;
    text-align:left;
}
.last_updated_at {
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    grid-area: last_updated_at;
    text-align:right;
}
.last_updated_at::before {
    content: 'Last Updated';
    font-size:0.7em;
}
.location {
    grid-area: location;
    text-align:left;
}
</style>