<template>
<div class="printing-notice">
  <h1>Printing</h1>
  <p>Please Wait</p>
  <button @click="$router.go(-1)">Back</button>
</div>
  <div class="printing-content batch_label_main">
    <p class="name">{{ batch.name }}</p>
    <p class="qty">{{ batch.quantity }}</p>
    <canvas id="qrcanvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
    props: {
        uuid: {
            required: true
        }
    },
    data: function () {
        return {
            barcode_code: this.uuid.split('-')[4],
            batch: {},
            save_status: null,
            editing: false,
            total_cost: 0
        }
    },
    methods: {
        afterprint(){
          window.removeEventListener('afterprint', this.afterprint)
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000)
            // window.location.back()
        }
    },
    async mounted () {
        this.batch = await this.$db.getBatch(this.uuid)
        
        var canvas = document.getElementById('qrcanvas')

        QRCode.toCanvas(canvas, this.barcode_code, function (error) {
          if (error) console.error(error)
          console.log('success!');
        })

        setTimeout(() => {
            window.print()
        }, 500)
        // window.addEventListener('afterprint', this.afterprint)
    }
}
</script>

<style scoped>

    body { 
        writing-mode: tb-rl;
    }


@media only print {
.batch_label_main {
  box-sizing: border-box;
  /* border:solid 2mm transparent; */
  /* background-color:fuchsia; */
  /* width:90mm;
  height:29mm; */
  width: var(--width);
  height: var(--height);
  display: grid;
  padding:2mm;
  grid-template-areas: "qty name" "barcode barcode";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 29mm;
  font-family:monospace;
  background-color:#fab;
}
.name {
  font-size: 6mm;
  grid-area: name;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  align-self:center;
  line-height: 1em;
  writing-mode: vertical-rl;
}
.qty {
  font-size: 3mm;
  grid-area: qty;
  margin: 0;
  line-height: 1em;
  text-align: right;
  align-self:center;
  writing-mode: vertical-rl;
}
#qrcanvas {
  width: 100% !important;
  height:100% !important;
  object-fit: contain;
  grid-area: barcode;
  align-self:flex-end;
  transform:rotate(90deg);
}
}
</style>