// eslint-disable-next-line no-unused-vars
window.onerror = function (message, source, lineno, colno, error) {
    alert('ERROR ' + message)
    // alert(source)
    // alert(lineno)
    // alert(colno)
    // alert(error)
};



import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'

// import {
//     setupAuth
// } from './auth'
import NurseryDB from './indexdb'

// eslint-disable-next-line no-undef
window.commit_hash = COMMIT_HASH;
// eslint-disable-next-line no-undef
window.commit_date = COMMIT_DATE;

// eslint-disable-next-line no-unused-vars
let app = createApp(App).use(router)

// function callbackRedirect(appState) {
//     router.push(
//         appState && appState.targetUrl ?
//         appState.targetUrl :
//         '/'
//     );
// }


// eslint-disable-next-line no-unused-vars
const INDEXEDDB = {
    // eslint-disable-next-line no-unused-vars
    install: (app, options) => {
        app.config.globalProperties.$db = new NurseryDB()
    }
}

app.use(INDEXEDDB).mount('#app')

// setupAuth({
//         "domain": "meso.au.auth0.com",
//         "client_id": "14Qb93Q5hhBbQjMVIfCV5gnj6mkZsjoq",
//         "redirect_uri": window.location.origin
//     }, callbackRedirect).then((auth) => {
//     app.use(auth).use(INDEXEDDB).mount('#app')
// })

// Check that service workers are supported
if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
    });
}