/* eslint-disable no-fallthrough */
import {
    openDB,
    deleteDB
} from 'idb/with-async-ittr.js';

import {
    v4 as uuidv4
} from 'uuid';

class NurseryDB {
    _db = null

    _version = 5

    constructor () {
        this.init()
    }

    async init() {
        this._db = openDB('Nursery', this._version, {
            // eslint-disable-next-line no-unused-vars
            upgrade(db, old_version, new_version, transaction) {
                console.log('UPGRADING INDEXEDDB', old_version, 'to', new_version)
                switch (old_version) {
                    case 0: 
                        // Create a store of objects
                        db.createObjectStore('batches', {
                            // The 'id' property of the object will be the key.
                            keyPath: 'uuid',
                            autoIncrement: false,
                        });

                        // eslint-disable-next-line no-case-declarations
                        const batch_notes = db.createObjectStore('batch_notes', {
                            // The 'id' property of the object will be the key.
                            keyPath: 'uuid',
                            autoIncrement: false,
                        });
                        batch_notes.createIndex('batch_uuid', 'batch_uuid')

                        // eslint-disable-next-line no-case-declarations
                        const batch_costs = db.createObjectStore('batch_costs', {
                            // The 'id' property of the object will be the key.
                            keyPath: 'uuid',
                            autoIncrement: false,
                        });
                        batch_costs.createIndex('batch_uuid', 'batch_uuid')


                        
                        // Create an index on the 'date' property of the objects.
                        // store.createIndex('date', 'date');
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    // eslint-disable-next-line no-fallthrough
                    case 5: 
                        db.createObjectStore('log', {
                            keyPath: 'log_uuid',
                            autoIncrement: false,
                        });
                    

                }
                
            },
        });
    }

    async deleteDatabase() {
        (await this._db).close()
        await deleteDB('Nursery', {
            blocked() {
                throw new Error('Delete Request Blocked')
            }
        })
    }

    async log(table, data, deleted = false) {
        await (await this._db).put('log', {
            log_uuid: uuidv4(),
            log_table: table,
            item_uuid: data.uuid,
            updated_at: Date.now(),
            deleted: deleted,
            data: data
        });
    }
    async getSyncLogLength() {
        return await (await this._db).transaction('log').store.count()
    }

    async fetchLog(page = 1, per_page = 10) {
        const log = (await this._db).transaction('log').store

        let at_start = (page === 1 ? true : false)
        const total_results = await log.count()

        const data = {
            page,
            per_page,
            total_results,
            last_page: Math.ceil(total_results / per_page),
            at_end: false,
            data: []
        }

        for await (const cursor of log) {
            if (!at_start) {
                cursor.advance((page - 1) * per_page)
                at_start = true
            } else {
                data.data.push(cursor.value)
                if (data.data.length === per_page) {
                    return data
                }
            }
        }
        // Early end of results
        data.at_end = true
        return data
    }

    async deleteLog(log_uuid) {
        await (await this._db).delete('log', log_uuid)
    }

    async put(table, data) {
        const _data = {}
        for(let key in data) {
            if (!key.startsWith('_')) {
                _data[key] = data[key]
            }
        }
        await (await this._db).put(table, _data);
        await this.log(table, _data)
    }


    async syncDB(table, data) {
        console.log('SYNCDB', table, data)
        await Promise.all(data.data.map(async item => {
            // NOTE: Dont use this.put here or we get an infinite loop of updates
            await (await this._db).put(table, item.data.data)
        }))
    }


    async fetchBatches(page = 1, per_page = 2, search_string = null) {
        search_string = search_string.toLowerCase()
        const batches = (await this._db).transaction('batches').store

        let at_start = (page === 1 ? true : false)
        const total_results = await batches.count()

        const data = {
            page,
            per_page,
            total_results,
            last_page: Math.ceil(total_results / per_page),
            at_end: false,
            data: []
        }

        for await (const cursor of batches) {
            if (!at_start) {
                cursor.advance((page - 1) * per_page)
                at_start = true
            } else {
                if (search_string) {
                    if (Object.values(cursor.value).filter(v => v && v.includes && v.toLowerCase().includes(search_string)).length) {
                        data.data.push(cursor.value)
                    }
                } else {
                    data.data.push(cursor.value)
                }
                if (data.data.length === per_page) {
                    return data
                }
            }
        }
        // Early end of results
        data.at_end = true
        return data
    }


    async saveBatch(batch) {
        if(batch.uuid) {
            console.log('UPDATING BATCH', batch)
        } else {
            console.log('CREATING BATCH', batch)
            batch.uuid = uuidv4()
        }
        batch = JSON.parse(JSON.stringify(batch))
        batch.last_updated_at = Date.now()
        await this.put('batches', batch)
        return batch
    }

    async getBatch(uuid) {
        console.log('LOADING BATCH', uuid)
        if(uuid.length === 12) {
            return (await this.fetchBatches(1, 1, uuid)).data[0]
        } else {
            return await (await this._db).get('batches', uuid);
        }
    }



    /***
     * BATCH NOTES
     */
    async fetchBatchNotes(batch_uuid, page = 1, per_page = 2, search_string = null) {
        const batch_notes = (await this._db).transaction('batch_notes').store.index('batch_uuid')

        let at_start = (page === 1 ? true : false)
        const total_results = await batch_notes.count()

        const data = {
            page,
            per_page,
            total_results,
            last_page: Math.ceil(total_results / per_page),
            at_end: false,
            data: []
        }

        for await (const cursor of batch_notes.iterate(batch_uuid)) {
            if (!at_start) {
                cursor.advance((page - 1) * per_page)
                at_start = true
            } else {
                if (search_string) {
                    if (Object.values(cursor.value).filter(v => v.contains(search_string))) {
                        data.data.push(cursor.value)
                    }
                } else {
                    data.data.push(cursor.value)
                }
                if (data.data.length === per_page) {
                    return data
                }
            }
        }
        // Early end of results
        data.at_end = true
        return data
    }


    async saveBatchNote(note) {
        if (note.uuid) {
            console.log('UPDATING BATCH NOTE', note)
        } else {
            console.log('CREATING BATCH NOTE', note)
            note.uuid = uuidv4()
        }
        note = JSON.parse(JSON.stringify(note))
        note.last_updated_at = Date.now()
        await this.put('batch_notes', note)
        return note
    }


    async deleteBatchNote(note_uuid) {
        await (await this._db).delete('batch_notes', note_uuid)
        await this.log('batch_notes', { uuid: note_uuid }, true)
    }


    /***
     * BATCH COSTS
     */
    async fetchBatchCosts(batch_uuid, page = 1, per_page = 2, search_string = null) {
        const batch_costs = (await this._db).transaction('batch_costs').store.index('batch_uuid')

        let at_start = (page === 1 ? true : false)
        const total_results = await batch_costs.count()

        const data = {
            page,
            per_page,
            total_results,
            last_page: Math.ceil(total_results / per_page),
            at_end: false,
            data: []
        }

        for await (const cursor of batch_costs.iterate(batch_uuid)) {
            if (!at_start) {
                cursor.advance((page - 1) * per_page)
                at_start = true
            } else {
                if (search_string) {
                    if (Object.values(cursor.value).filter(v => v.contains(search_string))) {
                        data.data.push(cursor.value)
                    }
                } else {
                    data.data.push(cursor.value)
                }
                if (data.data.length === per_page) {
                    return data
                }
            }
        }
        // Early end of results
        data.at_end = true
        return data
    }


    async autocompleteCostType(search_string = null, per_page = 10) {
        const batch_costs = (await this._db).transaction('batch_costs').store

        const data = []

        search_string = search_string.toLowerCase()

        // First look for starts with
        for await (const cursor of batch_costs) {
            if (cursor.value.type.toLowerCase().startsWith(search_string)
                && !data.includes(cursor.value.type)
            ){
                data.push(cursor.value.type)
            }
            if (data.length === per_page) {
                return data
            }
        }
        for await (const cursor of batch_costs) {
            if (cursor.value.type.toLowerCase().includes(search_string) &&
                !data.includes(cursor.value.type)
            ) {
                data.push(cursor.value)
            }
            if (data.length === per_page) {
                return data
            }
        }
        // Early end of results
        return data
    }


    async saveBatchCost(cost) {
        if (cost.uuid) {
            console.log('UPDATING BATCH COST', cost)
        } else {
            console.log('CREATING BATCH COST', cost)
            cost.uuid = uuidv4()
        }
        cost = JSON.parse(JSON.stringify(cost))
        cost.last_updated_at = Date.now()
        await this.put('batch_costs', cost)
        return cost
    }


    async deleteBatchCost(cost_uuid) {
        await (await this._db).delete('batch_costs', cost_uuid)
        await this.log('batch_costs', { uuid: cost_uuid }, true)
    }

}

export default NurseryDB