<template>
    <div class="batches-show">
        <form action="" @submit="submit" v-if="editing">
            <fieldset>
                <label for="name">Name</label>
                <input id="name" required type="text" name="name" v-model="batch.name">
            </fieldset>
            <fieldset>
                <label for="botanical_name">Botanical Name</label>
                <input id="botanical_name" type="text" name="botanical_name" v-model="batch.botanical_name">
            </fieldset>
            <fieldset>
                <label for="source">Source</label>
                <input id="source" type="text" name="source" v-model="batch.source">
            </fieldset>
            <fieldset>
                <label for="source_date">Source Date</label>
                <input id="source_date" type="date" name="source_date" @input="batch.source_date = $event.target.value" :value="batch.source_date && new Date(parseInt(batch.source_date)).toISOString().split('T')[0]">
            </fieldset>
            <fieldset>
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number" name="quantity" v-model="batch.quantity">
            </fieldset>
            <fieldset>
                <label for="location">Location</label>
                <input id="location" type="text" name="location" v-model="batch.location">
            </fieldset>
            <nav>
                <button type="button" @click="editing = false">Cancel</button>
                <button type="button" disabled v-if="save_status">{{save_status}}</button>
                <button v-else>Save Batch</button>
            </nav>
        </form>
        <section class="batch-brief" v-else>
            <h1 class="name">{{batch.name}}</h1>
            <i class="botanical_name">{{batch.botanical_name}}</i>
            <p class="quantity">{{batch.quantity}}</p>
            <p class="location">{{batch.location}}</p>
            <p class="total_cost">
                <span>{{total_cost}}</span>&nbsp;
                <small v-if="unit_cost">({{unit_cost}} ea)</small>
                </p>
            <p class="source">{{batch.source}}
                <i>{{batch.source_date}}</i>
            </p>
            <button type="button" @click="editing = true">Edit</button>

            <router-link class="btn" :to="`/batches/${batch.uuid}/label`">Print Label <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
</svg></router-link>
            <!-- <document-print v-if="batch.uuid" template="batch_label" label="Print Label" :data="batch"></document-print> -->
        </section>

        <section class="tabbed-interface">
            <ul>
                <li><label class="btn" for="tab_notes">Notes</label></li>
                <li><label class="btn" for="tab_costs">Costs</label></li>
            </ul>
            <input type="radio" id="tab_costs" name="tabbed_interface">
            <div>
                <batch-costs :batch_uuid="uuid" @total_cost="onTotalCost"></batch-costs>
            </div>
            <input type="radio" checked="checked" id="tab_notes" name="tabbed_interface">
            <div>
                <batch-notes :batch_uuid="uuid"></batch-notes>
            </div>
        </section>
        
    </div>
</template>

<script>
import BatchNotes from '@/components/BatchNotes.vue'
import BatchCosts from '@/components/BatchCosts.vue'
// import DocumentPrint from '@/components/DocumentPrint.vue'

export default {
    components: {
        BatchNotes,
        BatchCosts,
        // DocumentPrint
    },
    props: {
        uuid: {
            required: true
        }
    },
    data: function () {
        return {
            batch: {},
            save_status: null,
            editing: false,
            total_cost: 0,
            unit_cost: ''
        }
    },
    methods: {
        onTotalCost(value){
            this.total_cost = value;
            this.unit_cost = this.batch.quantity ? (value / parseFloat(this.batch.quantity)).toFixed(2) : ''
        },
        async submit(event) {
            event.preventDefault()
            try {
                this.save_status = 'Saving'
                await this.$db.saveBatch(this.batch)
                this.save_status = 'Save Complete'
                setTimeout(() => {
                    this.save_status = null
                    this.editing = false
                }, 500)
            } catch (error) {
                this.save_status = 'ERROR: '+error
                setTimeout(() => {
                    this.save_status = null
                }, 2000)
            }
        }
    },
    async mounted () {
        this.batch = await this.$db.getBatch(this.uuid)
    }
}
</script>

<style scoped>
.batch-brief {
    margin-top:1rem;
    display:grid;
    gap:0.5rem;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 
        'quantity name name'
        'quantity botanical botanical'
        'location total_cost source'
    ;
}
.batch-brief .name {
    grid-area:name;
}
.batch-brief .botanical-name {
    grid-area:botanical;
}
.batch-brief .total_cost {
    grid-area:total_cost;
    border-top:solid 1px #666;
}
.batch-brief .total_cost:before {
    content:'Total Cost: $'
}
.batch-brief .location {
    grid-area:location;
    text-align:left;
}
.batch-brief .source {
    color:#666;
    grid-area:source;
    display:flex;
    flex-direction:column;
    text-align:right;
    border-top:solid 1px #666;
    font-size:0.7em;
}
.batch-brief .quantity {
    grid-area: quantity;
    background-color:#333;
    color:#fff;
    padding:0 0.5em;
    font-weight:600;
    border-radius:0.5em;
    font-size:1.4em;
    display:flex;
    align-items:center;
    justify-content:center;
}
</style>