<template>
    <div class="barcode-scan">
        <h1>Barcode Scan</h1>
        <div id="qr-reader"></div>
        <p v-if="message">{{message}}</p>
    </div>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode'

export default {
    data () {
        return {
            barcodes: [],
            message: '',
            html5QrcodeScanner: null,
            has_scanned: false,
        }
    },
    methods: {
            onScanSuccess: async function(result) {
                if(this.has_scanned) return;

                console.log('THIS', this)
                const batch = await this.$db.getBatch(result)
                if (batch) {
                    this.has_scanned = true;
                    this.message = `<strong>Scanned '${result}', opening batch</strong>`
                    // Play positive sound effect
                    this.$router.push(`/batches/${batch.uuid}`)
                    await this.html5QrcodeScanner.clear();
                    
                } else { 
                    // Play negative sound effect
                    this.message = `<strong>Batch not found</strong><i>${result}</i>`
                }
            }
    },
    async beforeUnmount(){
        // await this.html5QrcodeScanner.clear();
        console.log('XXX', this.html5QrcodeScanner)
    },
    mounted () {
        this.html5QrcodeScanner = new Html5QrcodeScanner(
            "qr-reader", { fps: 10, qrbox: 250 });

        this.html5QrcodeScanner.render((result) => {
            console.log(this, result)
            this.onScanSuccess(result)
        });
        console.log('SCAN', this.html5QrcodeScanner)
    }
}
</script>

<style>
#barcode-scanner-container {
    position:relative;
}

#barcode-scanner-container video {
    max-width:100%;
}
#barcode-scanner-container canvas.drawingBuffer {
    position:absolute;
    top:0;
    left:0;
    max-width:100%;
}
#barcode-scanner-container>p {
    z-index:1000;
    background-color:rgba(0,0,0,0.5);
    padding:1em;
    color:#fff;
    position:absolute;
    bottom:10%;
    left:50%;
    transform:translateX(-50%);
    text-align:center;
    display:flex;
    flex-direction:column;
}
</style>