<template>
  <div class="home">
    <h1>Menu</h1>
    <button class="danger" @click="deleteDatabase()">Delete Local Database</button>

    <form @submit.prevent="save_security_key">
      <fieldset>
        <label for="security_key">Security Key</label>
        <input v-model="security_key" type="text" id="security_key">
        <button>Save</button>
      </fieldset>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data(){
    return {
      security_key: localStorage.getItem('security_key')
    }
  },
  methods: {
    save_security_key(){
      localStorage.setItem('security_key', this.security_key)
    },
    async deleteDatabase() {
      if (confirm('Delete Local Database? Any unsynced changes will be lost')) {
        try {
          await this.$db.deleteDatabase()
          alert('Database deleted; Reloading')
          window.location.reload()
        } catch (error) {
          alert(error)
        }
      }
    }
  },
  created(){
    console.log(process.env)
  }
}
</script>
